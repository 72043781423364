import { Collection } from "~/repository/main/collection";
import type * as firebaseTypes from "~/types/firebase";
import { type NotificationCategoryData, type NotificationData } from "~/types/notification";

export class NotificationMessageCollection extends Collection {
  constructor() {
    super("message");
  }

  public async addNotificationMessageData(item: firebaseTypes.addNotificationMessageData) {
    return await this._addDocumentData(item);
  }

  public async getNotificationData() {
    return (await this._getDocumentData()) as Array<NotificationData>;
  }
}

export class NotificationTargetCollection extends Collection {
  constructor(messageId: string) {
    super(`message/${messageId}/target`);
  }

  public async addNotificationTargetData(item: firebaseTypes.addNotificationTargetData) {
    return await this._addDocumentData(item);
  }

  public async getNotificationTargetData() {
    return await this._getDocumentData();
  }

  public async addBatchNotificationTargetData(
    batchItem: firebaseTypes.addNotificationTargetData[],
    batchSize: number = 1000
  ) {
    for (let i = 0; i < batchItem.length; i += batchSize) {
      const batchData = batchItem.slice(i, i + batchSize);
      await this._addBatchDocumentData(batchData);
    }
  }
}

export class NotificationCategoryCollection extends Collection {
  constructor() {
    super(`message_category`);
  }

  public async getCategoryData() {
    return (await this._getDocumentData()) as Array<NotificationCategoryData>;
  }
}
