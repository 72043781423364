import { Collection } from "~/repository/main/collection";
import type * as firebaseTypes from "~/types/firebase";

export type DoctorDataWithId = firebaseTypes.DoctorData & firebaseTypes.DocumentDataWithId;
type DoctorDataResponse = DoctorDataWithId[];

export class DoctorCollection extends Collection {
  constructor(hospitalId: string) {
    super(`hospital/${hospitalId}/staff`);
  }

  public async getDoctorData(): Promise<DoctorDataResponse> {
    return (await this._getDocumentData()) as DoctorDataResponse;
  }

  public async addDoctorData(email: string, password: string, data: any): Promise<string> {
    return await this._addAuthData(email, password, data);
  }
}
